function boom() {
  const accordions = document.querySelectorAll(".bowe-collapse ");
  if (!accordions.length) return null;

  accordions.forEach((item) => {
    let top = item.querySelector(".top");

    top.addEventListener("click", () => {
      item.classList.toggle("open");
    });
  });
}

document.addEventListener("DOMContentLoaded", boom);
